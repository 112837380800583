@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-LightOblique.eot');
  src: url('fonts/Avenir-LightOblique.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-LightOblique.woff2') format('woff2'),
      url('fonts/Avenir-LightOblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-MediumOblique.eot');
  src: url('fonts/Avenir-MediumOblique.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-MediumOblique.woff2') format('woff2'),
      url('fonts/Avenir-MediumOblique.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-Light.eot');
  src: url('fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-Light.woff2') format('woff2'),
      url('fonts/Avenir-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-Oblique.eot');
  src: url('fonts/Avenir-Oblique.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-Oblique.woff2') format('woff2'),
      url('fonts/Avenir-Oblique.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Black Oblique';
  src: url('fonts/Avenir-BlackOblique.eot');
  src: url('fonts/Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-BlackOblique.woff2') format('woff2'),
      url('fonts/Avenir-BlackOblique.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-HeavyOblique.eot');
  src: url('fonts/Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-HeavyOblique.woff2') format('woff2'),
      url('fonts/Avenir-HeavyOblique.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('fonts/Avenir-BookOblique.eot');
  src: url('fonts/Avenir-BookOblique.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-BookOblique.woff2') format('woff2'),
      url('fonts/Avenir-BookOblique.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-Medium.eot');
  src: url('fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-Medium.woff2') format('woff2'),
      url('fonts/Avenir-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('fonts/Avenir-Book.eot');
  src: url('fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-Book.woff2') format('woff2'),
      url('fonts/Avenir-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-Roman.eot');
  src: url('fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-Roman.woff2') format('woff2'),
      url('fonts/Avenir-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-Black.eot');
  src: url('fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-Black.woff2') format('woff2'),
      url('fonts/Avenir-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('fonts/Avenir-Heavy.eot');
  src: url('fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
      url('fonts/Avenir-Heavy.woff2') format('woff2'),
      url('fonts/Avenir-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}